@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?cl56d2');
  src:  url('fonts/icomoon.eot?cl56d2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?cl56d2') format('truetype'),
    url('fonts/icomoon.woff?cl56d2') format('woff'),
    url('fonts/icomoon.svg?cl56d2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-transmitted:before {
  content: "\e913";
}
.icon-drafted:before {
  content: "\e914";
}
.icon-resend:before {
  content: "\e911";
}
.icon-revoke:before {
  content: "\e912";
}
.icon-document-outline:before {
  content: "\e90e";
}
.icon-filters:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e90f";
}
.icon-broken-link:before {
  content: "\e90c";
}
.icon-email:before {
  content: "\e90d";
}
.icon-delete-outlined:before {
  content: "\e909";
}
.icon-edit-outlined:before {
  content: "\e90a";
}
.icon-user-profile:before {
  content: "\e90b";
}
.icon-text-box-plus:before {
  content: "\e906";
}
.icon-card-account-details:before {
  content: "\e907";
}
.icon-suitcase:before {
  content: "\e908";
}
.icon-SSN-Verification:before {
  content: "\e901";
}
.icon-Document-Verification:before {
  content: "\e900";
}
.icon-Biometric-Verification:before {
  content: "\e902";
}
.icon-Address-Verification:before {
  content: "\e903";
}
.icon-Signature-Verification:before {
  content: "\e904";
}
.icon-Supplemental-Documents:before {
  content: "\e905";
}
