//add your custom classes

.h-100-overflow-h {
  height: calc(100vh - 55px);
  overflow-y: auto;
  overflow-x: hidden;
}

.y-auto-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .MuiTouchRipple-root {
    font-style: italic;
  }
}

//width per clasess
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

//font-sizes
.text-font-20 {
  font-size: 20px;
}

.text-font-22 {
  font-size: 22px;
}

.text-font-26 {
  font-size: 26px;
}

//margins custom classes
.margin-right-12 {
  margin-right: 12px;
}

//textFieldSearchWithIcon
.textFieldSearchWithIcon {
  .MuiFormControl-root {
    .Mui-focused {
      .icon-filter,
      .icon-search {
        color: $textColor-300 !important;
      }
    }
  }

  .MuiTextField-root {
    [class*='icon-'] {
      font-size: 1.25rem;
    }

    .icon-filter {
      padding-top: 2px;
    }
  }
}

//btnwithIcon height/width fixed
.btnWithIcon {
  height: 38px;
  width: 38px !important;
}

//customChips
.customChips {
  padding: 10px 10px !important;
  height: 24px;
  @extend .text-small;
  @extend .font-weight-medium;
  @include border-radius(40px);
}

//input search with icon
.iconSearchFont-22 {
  font-size: 22px;
}

.actionIconFont-24 {
  font-size: 24px;
}

.topContainerBtn {
  .MuiButton-root {
    width: 140px !important;
  }
}

//verticalTab Style
.MuiTabs-root {
  &.MuiTabs-vertical {
    .MuiButtonBase-root {
      &:first-child {
        .MuiBox-root {
          img {
            height: 269px !important;
            object-fit: cover;
          }
        }
      }

      .MuiGrid-root {
        &.MuiGrid-container {
          margin-bottom: 12px;
          margin-top: 3px;

          .iconTab {
            [class*='icon-'] {
              font-size: 24px;
              margin-top: 8px !important;
            }
          }

          .tabText {
            padding-left: 12px;
            padding-top: 9px;
            @extend .text-large;
            @extend .textColor-300;
          }
        }
      }

      &.Mui-selected {
        .MuiGrid-root {
          &.MuiGrid-container {
            .tabText {
              font-weight: 600 !important;
              @extend .textColor-200;
            }
          }
        }
      }
    }
  }
}

//custom Modal Footer
.customModalFooter {
  position: absolute !important;
  right: 0px;

  .MuiButton-root {
    width: 130px !important;

    &:first-child {
      margin-right: 16px;
    }
  }
}

//customChips
.customChips {
  .MuiChip-root {
    padding: 6px 10px !important;
    height: 24px;
    @extend .text-small;
    @extend .font-weight-medium;
    @include border-radius(40px);

    .MuiChip-label {
      margin-right: 0px !important;
      @extend .font-weight-semibold;
    }
  }
}

//list item
.listItemID {
  span {
    @extend .font-weight-medium;
  }
}

.marginTP-2 {
  margin-top: 2px;
}

.idModals {
  .icon-alignment {
    @extend .p-absolute;
    left: 32px;
    top: -2px;
  }
}

.font-size-16 {
  font-size: 16px !important;
}

.textReplyArea {
  .replyButton {
    @extend .p-absolute;
    top: 22px;
    right: 13px;
  }
}

//richtexteditor
.rsw-editor {
  min-height: 160px !important;
  border-radius: 4px !important;
}

.anchorTagStyle {
  text-decoration: none;
  color: inherit;
}

//modal dialog opacity
.declineBoxOpacity {
  .MuiBackdrop-root {
    opacity: 0.7 !important;
  }
}

.declineBoxOpacityInviteList {
  .MuiBackdrop-root {
    opacity: 0.07 !important;
  }
}

.confirmDialogBoxOpacity {
  .MuiBackdrop-root {
    opacity: 0.07 !important;
  }
}

.confirmDialogBoxOpacityNote {
  .MuiBackdrop-root {
    opacity: 0.5 !important;
  }
}

.minHeightContainer {
  min-height: calc(100vh - 105px);
}

@media (min-width: 1023px) {
  [class*='makeStyles-uploadedVideo-'] {
    video {
      height: 351px !important;
    }
  }
}

.text-white {
  color: $white;
}

//Tcard box style
.MuiPaper-root {
  &.MuiPaper-elevation {
    &.MuiPaper-rounded,
    &.MuiPaper-elevation1 {
      box-shadow: none !important;
      border-color: $borderColor !important;
      @include border-radius(10px);
    }
  }
}

.border-none {
  border: none !important;
}

.MuiAutocomplete-popper {
  .MuiPaper-root {
    &.MuiPaper-elevation {
      box-shadow: none !important;
      border: 1px solid $borderColor;
      @include border-radius(10px);
    }
  }
}

//popup css for merchant
.MuiModal-root {
  .MuiDialog-container {
    .MuiPaper-root {
      .MuiBox-root {
        h2 {
          @extend .text-h3;
        }
      }

      .MuiDialogContent-root {
        @extend .text-large;
      }

      .MuiDialogActions-root {
        padding: 0px 40px 40px 40px !important;
      }
    }
  }
}

.tcard-broder {
  border: 1px solid $borderColor;
}

.loginBox-min-height {
  min-height: 34.175rem;
}

.height-vh-100 {
  height: calc(100vh - 6rem);
}

.mailIcon {
  @extend .icon-size-32;
  @extend .p-absolute;
  left: 48% !important;
  top: 28% !important;
}

.forgotPasswordOuterContainer {
  width: 56rem;
}

//communication document list table with scroll
.doc-list-table {
  .MuiTable-root {
    .MuiTableHead-root {
      display: table;
      width: 100%;
      table-layout: fixed;

      tr {
        th {
          &:first-child {
            width: 40%;
          }

          border-bottom: 0px solid $textColor-500 !important;

          &.MuiTableCell-root {
            padding: 0px !important;
          }
        }
      }

      .MuiTableCell-head {
        .MuiButton-root {
          font-size: 0.75rem !important;
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }

    tbody {
      display: block;
      max-height: 200px;
      overflow-y: auto;
      @include commonScrollbarBar;

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        td {
          &:first-child {
            width: 40%;
            word-wrap: break-word;
          }

          font-size: 0.75rem !important;
          padding: 8px 0px;
          border-bottom: 0px solid $textColor-500 !important;

          div {
            @include font-weight-semibold();
          }

          [class*='makeStyles-linkText-'],
          .MuiBox-root {
            font-size: 0.75rem !important;
            @include font-weight-semibold();
          }
        }
      }
    }
  }
}

.MuiCalendarPicker-root {
  @extend .tcard-broder;
}

//btn styles overrid for applican't tab
.textTruncate {
  @extend .text-ellipsis;
  max-width: 130px;
}
.textTruncateLargeWidth {
  max-width: 180px !important;
}

.removeCursorPointer {
  cursor: default !important;
}

//category horizontal scroll
.category-scroll {
  overflow-x: auto;
}

.entityTagContainer {
  background: $bgLightDarkColor;
  border: 1px solid $backgroundColor;
  @include border-radius(12px);
  width: 2.625rem;
  &.small-badge {
    width: 1.825rem !important;
  }
}

.tagListAlignment {
  display: block !important;
  height: fit-content !important;
  text-align: left !important;

  .tagSection {
    display: inline-block;
  }

  .customChips {
    margin: 8px 16px 0px 0px;
    width: fit-content;
  }
}

.confirmDialogBoxOpacityList {
  .MuiBackdrop-root {
    opacity: 0.3 !important;
  }
}

.addTagButton {
  right: 12px;
  top: 40px;
}

.noTagsAvailable {
  @include border-radius(4px);
  border: 1px solid $borderColor;
}

.noteDetailsBoxHeight {
  min-height: calc(100vh - 275px);
}

.disclaimerSectionRedius {
  @include border-radius(10px);
  max-width: 21.875rem;
}

.entityBadge {
  @include border-radius(16px);
  background-color: $backgroundColor;
}

//notification indicator
.notificationIndicator {
  @extend .p-relative;
  &::after {
    @extend .p-absolute;
    content: '';
    height: 8px;
    width: 8px;
    bottom: 24px;
    left: 14px;
    @include border-radius(50%);
  }
}

.merchantNotifications {
  height: calc(100vh - 260px);
}

.popOverContainer {
  height: calc(100vh - 300px);
}

//drawer ui changes
.profileSection {
  @extend .p-relative;
  height: 6.25rem;
  width: 13.75rem;
  background-color: $profileSectionBgColor !important;
  @include border-radius(4px);
  box-shadow: 0px 10px 20px $profileSectionBoxShadow;
  .profileCircle {
    @extend .p-absolute;
    height: 83px;
    width: 83px;
    border: 1px solid $white;
    @include border-radius(50%);
    top: -42px;
    left: 70px;
  }

  //collapse view
  &.collapseView {
    width: 5rem !important;
    .profileCircle {
      height: 46px !important;
      width: 46px !important;
      top: -25px;
      left: 19px;
      .avatarBox {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }
}
.merchantName {
  bottom: 32px;
  @extend .p-absolute;
}

.boxCardMember {
  @include border-radius(10px);
  border: 1px solid $textColor-500;
}

.fileListContainer {
  @include commonScrollbarBar;
  overflow-y: scroll;
  max-height: 180px;
}

.addProfileImgBtn {
  @include border-radius(50% !important);
  height: 2.5rem;
  width: 2.5rem;
  bottom: 51px;
  right: -136px;
}

.tableCellMaxWidth {
  max-width: 8rem;
}

.listItemStyle {
  background: $inputBgColor;
  border: 1px solid $borderColor;
  @include border-radius(4px);
}

.accreditationIconContainer {
  width: 40px;
  height: 40px;
  @include border-radius(50%);
}

.accrediationBadgeContainer {
  width: 72px;
  height: 72px;
  background-color: $white;
  @include border-radius(50%);
}

.accrediatedSection {
  background: $inputBgColor;
  border: 1px dashed $borderColor;
  @include border-radius(8px);
}

//TODO: need to move this into common.
#scrollableDiv {
  #pandaDocIFrame {
    height: 100vh !important;
  }
}

.iconArrowUp {
  transform: rotate(89deg);
}

.entitySummaryContainer {
  border: 1px solid $borderColor;
  @include border-radius(8px);
  .text-ellipsis {
    max-width: 30rem;
  }
}

.manageEntitiesTableCellMaxWidth {
  max-width: 90% !important;
  width: 90% !important;
}
